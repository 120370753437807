import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Box, Flex, Text } from 'theme-ui';
import "./layout.css";

export interface LayoutProps {
  title: string;
}

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  return (
    <Flex sx={{
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Box
        className="page flex-vertical"
        sx={{width: ["600px", "800px", "1000px", "1400px"]}}
      >
        <Helmet>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{title}</title>
        </Helmet>
        <div className="header">
          <StaticImage src="../../images/banner.png" alt="neocities"/>
          <div className="navigation-bar l">
            <Link className="header-link" to="/">
              Home
            </Link>
            <Link className="header-link" to="/ladyverse">
              The Ladyverse
            </Link>
            <Link className="header-link" to="/trixieverse">
              The Trixieverse
            </Link>
            <Link className="header-link" to="/media">
              Media
            </Link>
            <Link className="header-link" to="/about">
              About
            </Link>
          </div>
        </div>
        <div className="content">{children}</div>

        <Flex className="footer flex-horizontal" sx={{ justifyContent: 'space-between'}}>
          <a href="https://itch.io/c/449424/the-lovely-ladyverse">
            <StaticImage src="../../images/icon_itch.svg" alt="itch.io" height={30}/>
          </a>
          <Box>
            <Text sx={{
              marginRight: 3,
            }}>This site was written by Digital Poppy!</Text>
            <a href="https://neocities.org">
              <StaticImage src="../../images/neocities.png" alt="neocities" height={30}/>
            </a>
          </Box>
        </Flex>
      </Box>
    </Flex>

  );
};

export default Layout;
